// JavaScript Document

// Scripts written by Jordan Bearce @ Weblinx, Inc.

import { library, dom } from "@fortawesome/fontawesome-svg-core";

import { faAngleDown       as fasFaAngleDown       } from "@fortawesome/pro-solid-svg-icons/faAngleDown";
import { faBars            as fasFaBars            } from "@fortawesome/pro-solid-svg-icons/faBars";
import { faEnvelope        as fasFaEnvelope        } from "@fortawesome/pro-solid-svg-icons/faEnvelope";
import { faMapMarkerAlt    as fasFaMapMarkerAlt    } from "@fortawesome/pro-solid-svg-icons/faMapMarkerAlt";
import { faPhone           as fasFaPhone           } from "@fortawesome/pro-solid-svg-icons/faPhone";
import { faXmark           as fasFaXmark           } from "@fortawesome/pro-solid-svg-icons/faXmark";

import { faInstagram       as fabFaInstagram       } from "@fortawesome/free-brands-svg-icons/faInstagram";
import { faFacebook        as fabFaFacebook        } from "@fortawesome/free-brands-svg-icons/faFacebook";

/**
 * Add solid icons
 */
library.add(fasFaAngleDown, fasFaBars, fasFaEnvelope, fasFaMapMarkerAlt, fasFaPhone, fasFaXmark);

/**
 * Add brands icons
 */
library.add(fabFaInstagram, fabFaFacebook);


/**
 * Watch the DOM to insert icons
 */
dom.watch();
